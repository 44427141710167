import { useSocketIoContext } from "~/contexts/SocketIoContext";
import type { ShouldRevalidateFunction } from "~/remix";
import { Outlet, json, useLoaderData, useParams, useRouteLoaderData } from "~/remix";

import type { LoaderFunctionArgs } from "@remix-run/node";
import { useCallback, useEffect } from "react";
import invariant from "tiny-invariant";
import { getTheme } from "~/helpers/theme.server";
import { getGameBySlug, getRefreshModeFromGame } from "~/models/game.server";
import { getGameSettings } from "~/models/setting.server";
import { getUserOrLogout, requireUser } from "~/session.server";

import type { ThemeDef } from "~/components/Theme/ThemeSelect";
import { emitter } from "~/helpers/emitter.server";
import { useThemeChange } from "~/hook/useThemeChange";

export const shouldRevalidate: ShouldRevalidateFunction = ({
  defaultShouldRevalidate,
  formAction
}) => {
  if (formAction?.includes('/player')) {
    return false;
  }
  return defaultShouldRevalidate;
}

export const useSlugLoaderData = () => {
  const ret = useRouteLoaderData<typeof loader>('routes/game/$slug')
  return ret;
};

export async function loader({ request, params }: LoaderFunctionArgs) {
  const { slug } = params
  invariant(slug, 'Slug must be defined');
  const [game, slugTheme, user] = await Promise.all([
    getGameBySlug(slug ?? ''),
    getTheme(request, slug),
    getUserOrLogout(request),
    requireUser(request),
  ])

  invariant(game, 'Game not found');
  const [tmp, tmpRefreshMode, themes0] = await Promise.all([
    getGameSettings(game?.id ?? 0),
    getRefreshModeFromGame(game),
    getGameSettings(0),
  ])

  let refreshMode = tmpRefreshMode;

  if (refreshMode === 'AUTO') {
    if (emitter.eventNames().length === 0 || user.roles.includes('role_guest')) {
      refreshMode = 'BUTTON'
    }
  }

  const availableThemes = (tmp.get('themes') ?? themes0.get('themes') ?? []) as ThemeDef[];
  const { gameMasterId } = game;

  let theme = slugTheme;

  return json({
    availableThemes,
    slugTheme: theme,
    refreshMode,
    slug,
    gameMasterId,
  }, {
    headers: {
      // 'Cache-Control': 'public, max-age=60'
    }
  })
}

export const useSlugRouteLoaderData = () => {
  const ret = useRouteLoaderData<typeof loader>("routes/game/$slug")
  invariant(ret, "Unable to read $slug loader");
  return ret;
}

export default function IndexPage() {
  const { slug } = useParams();
  const { slugTheme } = useLoaderData<typeof loader>();
  const { bindIdentity } = useSocketIoContext()
  useThemeChange(slugTheme);

  const boSlug = useCallback((slug: string) => {
    if (slug) {
      bindIdentity(slug);
    }
  }, [bindIdentity])

  useEffect(() => {
    if (!slug) return;
    boSlug(slug)
  }, [slug, boSlug]);


  if (!slug) return undefined;

  return <div data-layout="play">
    <Outlet />
  </div>
}

